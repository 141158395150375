import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@ui/modules/pipes/dt-translate/dt-translate.module';

import {LegacyIconModule} from '../../legacy-icon/legacy-icon.module';
import {FlushInputFieldComponent} from './flush-input-field.component';

@NgModule({
  declarations: [FlushInputFieldComponent],
  imports: [CommonModule, LegacyIconModule, ReactiveFormsModule, DtTranslateModule],
  exports: [FlushInputFieldComponent]
})
export class FlushInputFieldModule {}
