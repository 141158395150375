import {Injectable} from '@angular/core';
import {DtTranslateService} from '@ui/modules/pipes/dt-translate/services/dt-translate.service';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtTranslationsResolver {
  constructor(private dtTranslateService: DtTranslateService) {}

  resolve(): Observable<boolean> {
    return this.dtTranslateService.getTranslation('en').pipe(map(() => true));
  }
}
