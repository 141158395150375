import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {DtLabelsService} from '@ui/modules/directives/dt-labels/services/dt-labels.service';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtLabelLoaderGuard {
  constructor(private dtLabelsService: DtLabelsService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const {production} = route.data['environment'];
    this.dtLabelsService.initLabel(!production);
    return of(true);
  }
}
