import {isPlatformServer} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DtLabels} from '@ui/modules/directives/dt-labels/models/dt-labels.model';

@Injectable({
  providedIn: 'root'
})
export class DtLabelsService {
  private _origin: string;

  get origin(): string {
    return this._origin;
  }

  get isMainLabel(): boolean {
    return this.origin === DtLabels.MAIN;
  }

  get isRomanianLabel(): boolean {
    return this.origin === DtLabels.ROMANIAN;
  }

  private platformId = Inject(PLATFORM_ID);

  constructor() {}

  initLabel(devToolsEnabled?: boolean): void {
    if (!isPlatformServer(this.platformId)) {
      const url = new URL(window.location.href);
      let origin = url.hostname.replace('app.', '');
      if (devToolsEnabled) {
        origin = localStorage.getItem('labelOrigin') || origin;
      }
      this._origin = origin;
    }
  }
}
