import {Injectable} from '@angular/core';
import {SafeHtml} from '@angular/platform-browser';
import {TranslateService as NgxTranslateService} from '@ngx-translate/core';
import {DtScriptsService} from '@ui/core/services/dt-scripts.service';
import {concat, Observable} from 'rxjs';

import {DtMessage, DtMessageConfig} from '../models/translate.model';

@Injectable({
  providedIn: 'root'
})
export class DtTranslateService {
  private _debugModeEnabled = false;
  private supportedLanguages: string[] = [];
  private defaultLanguage = 'en';

  get debugModeEnabled(): boolean {
    return this._debugModeEnabled;
  }

  constructor(
    private ngxTranslateService: NgxTranslateService,
    private dtScriptsService: DtScriptsService
  ) {}

  initTranslations(supportedLanguages: string[], defaultLang?: string): void {
    this.addLangs(supportedLanguages);
    this.setDefaultLang(defaultLang || this.defaultLanguage);
    // this.use(this.getBrowserLang());
  }

  getTranslation(lang: string): Observable<unknown> {
    return this.ngxTranslateService.getTranslation(lang);
  }

  setTranslation(lang: string, translations: object): void {
    this.ngxTranslateService.setTranslation(lang, translations);
  }

  use(lang: string): void {
    if (this.supportedLanguages.includes(lang)) {
      this.ngxTranslateService.use(lang);
    }
  }

  addLangs(langs: string[]): void {
    this.supportedLanguages = langs;
    this.ngxTranslateService.addLangs(langs);
  }

  setLang(lang: string): void {
    this.ngxTranslateService.use(lang);
  }

  getBrowserLang(): string {
    return this.ngxTranslateService.getBrowserLang();
  }

  setDefaultLang(lang: string): void {
    this.ngxTranslateService.setDefaultLang(lang);
  }

  getDefaultLang(): string {
    return this.ngxTranslateService.getDefaultLang();
  }

  instant(key: DtMessage, params?: {[key: string]: string | SafeHtml}): string | any {
    if (this.debugModeEnabled) {
      return key;
    }
    const message: any = this.resolveMessage(key, (key as DtMessageConfig).translateParams || params);
    return this.ngxTranslateService.instant(message.translateKey, message.translateParams);
  }

  resolveMessage(message: DtMessage, params?: {[key: string]: string | SafeHtml}, untranslated?: string): DtMessage {
    if (untranslated || (message as DtMessageConfig)?.untranslated) {
      return {
        translateKey: '_UNTRANSLATED_MESSAGE',
        translateParams: {message: (message as DtMessageConfig)?.untranslated || untranslated}
      };
    }
    if (!message && message !== '') {
      return {
        translateKey: '_UNTRANSLATED_MESSAGE',
        translateParams: {message: ''}
      };
    }
    if (typeof message === 'string') {
      return {
        translateKey: message,
        translateParams: params
      };
    }
    if (message.translateKey) {
      return {
        translateKey: message.translateKey,
        translateParams: params
      };
    }
    return {
      translateKey: '_UNTRANSLATED_MESSAGE',
      translateParams: {message: 'INCORRECT TRANSLATION FORMAT'}
    };
  }

  setDebugMode(value: boolean): void {
    this._debugModeEnabled = value;
  }

  setEditMode(value: boolean): void {
    const crowdinParamsScriptId = 'crowdin-params-script';
    const crowdinParamsScript = document.getElementById(crowdinParamsScriptId);
    const crowdinScriptId = 'crowdin-script';
    const crowdinScript = document.getElementById(crowdinScriptId);
    if (value) {
      const crowdinParamsRequest = this.dtScriptsService.loadScript({
        id: crowdinParamsScriptId,
        content: `let _jipt = [['project', 'hunsy']];`
      });
      const crowdinRequest = this.dtScriptsService.loadScript({
        id: crowdinScriptId,
        src: 'https://cdn.crowdin.com/jipt/jipt.js'
      });
      if (!crowdinParamsScript && !crowdinScript) {
        concat(crowdinParamsRequest, crowdinRequest).subscribe(() => {
          this.setLang('en-PT');
        });
      }
    } else {
      if (crowdinParamsScript || crowdinScript) {
        window.location.reload();
      }
    }
  }
}
