import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DtDialogService} from '@ui/modules/components/dt-dialogs/services/dt-dialog.service';
import {DtMapDialogComponent} from '@ui/modules/components/dt-location-select/components/dt-map-dialog/dt-map-dialog.component';
import {DtLocation} from '@ui/modules/components/dt-location-select/models/dt-location-select.model';
import {DtLocationSelectService} from '@ui/modules/components/dt-location-select/services/dt-location-select.service';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'dt-location-select',
  templateUrl: './dt-location-select.component.html',
  styleUrls: ['./dt-location-select.component.scss']
})
export class DtLocationSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() locations: DtLocation[] = [];
  @Input() selectedLocationName: string;
  @Input() showPredefinedLocations: boolean;
  @Input() locationTimezoneCheck = true;

  @Output() changeLocation = new EventEmitter<DtLocation>();

  private destroyed$ = new Subject<void>();

  constructor(
    private dtDialogService: DtDialogService,
    private dtLocationSelectService: DtLocationSelectService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const {locations} = changes;
    if (locations) {
      if (this.showPredefinedLocations) {
        this.setPredefinedLocations();
      }
    }
  }

  ngOnInit(): void {
    if (this.showPredefinedLocations) {
      this.dtLocationSelectService.predefinedLocationsChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.setPredefinedLocations();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setPredefinedLocations(): void {
    const predefinedLocations = this.dtLocationSelectService.predefinedLocations;
    const uniqueLocations = this.locations.filter((location) => {
      return !predefinedLocations.find((item) => item.name === location.name);
    });

    this.locations = [...predefinedLocations, ...uniqueLocations];
  }

  setSelectedLocation(locationName: string): void {
    const selectedLocation = this.locations.find((location) => location.name === locationName);
    this.changeLocation.emit(selectedLocation);
  }

  openMapDialog(): void {
    this.dtDialogService
      .openDialog(DtMapDialogComponent, {data: {timezoneCheck: this.locationTimezoneCheck}})
      .afterClose.subscribe((selectedLocation: DtLocation) => {
        if (selectedLocation) {
          this.addLocation(selectedLocation);
          this.selectedLocationName = selectedLocation.name;
          this.changeLocation.emit(selectedLocation);
        }
      });
  }

  private addLocation(location: DtLocation): void {
    const locationExists = this.locations.find((item) => item.name === location.name);
    if (!locationExists) {
      this.locations.push(location);
    }
  }
}
