import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DtMedia} from '@ui/core/models/dt-media-content.model';
import {DtMessage} from '@ui/modules/pipes/dt-translate/models/translate.model';
import {NzModalRef} from 'ng-zorro-antd/modal';

import {SectionDescriptionComponentEnum} from '../../../section-description/section-description.component.enum';

export interface DialogButton {
  title: DtMessage;
  type: string;
  size: string;
  closeResult: unknown;
  closeCallback?: () => void;
  privacyCheck?: boolean;
}

export enum IconTypes {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  SECONDARY = 'secondary',
  WARNING = 'warning'
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() dialogTitle: DtMessage;
  @Input() iconType: IconTypes = IconTypes.SECONDARY;
  @Input() icon: string | null;
  @Input() sectionAlign: 'start' | 'center' | 'end' = 'center';
  @Input() title: DtMessage;
  @Input() description: DtMessage;
  @Input() descriptionAlign: string;
  @Input() media: DtMedia;
  @Input() content: TemplateRef<unknown>;
  @Input() privacyConfirm: boolean;
  @Input() buttons: DialogButton[];
  @Input() closable = true;

  readonly SectionDescriptionComponentEnum = SectionDescriptionComponentEnum;

  privacyCheckbox = [
    {
      name: 'verification-policy',
      label: 'VERIFICATION_POLICY',
      value: 'first'
    },
    {
      name: 'verification-second-policy',
      label: 'VERIFICATION_SECOND_POLICY',
      value: 'second'
    }
  ];
  privacyControl = new FormControl([]);

  get privacyConfirmed(): boolean {
    return this.privacyControl.value.length === this.privacyCheckbox.length;
  }

  constructor(private dialogRef: NzModalRef) {}

  ngOnInit(): void {}

  submitDialog(button: DialogButton) {
    if (button.closeCallback) {
      button.closeCallback();
    }
    this.dialogRef.close(button.closeResult);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
