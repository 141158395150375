import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DtMedia} from '@ui/core/models/dt-media-content.model';

@Component({
  selector: 'dt-media-content',
  templateUrl: './dt-media-content.component.html',
  styleUrls: ['./dt-media-content.component.scss']
})
export class DtMediaContentComponent implements OnInit {
  @Input() media: DtMedia;
  @Input() muted = true;
  @Input() autoplay: boolean;
  @Input() controls: boolean;

  @Output() mediaLoad = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  updateMediaLoad(videoElement?: HTMLVideoElement): void {
    if (videoElement && !this.autoplay) {
      videoElement.pause();
    }
    this.mediaLoad.emit();
  }
}
