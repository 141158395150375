import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DtMedia} from '@ui/core/models/dt-media-content.model';
import {DtPaginationResponse} from '@ui/core/models/dt-pagination.model';
import {DtMediaContentService} from '@ui/core/services/dt-media-content.service';
import {DtProfilesService} from '@ui/core/services/dt-profiles.service';
import {
  BehaviorSubject,
  catchError,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  repeat,
  shareReplay,
  tap,
  throwError
} from 'rxjs';

import {environment} from '../../../../../../environments/environment';
import {RestrictionTypes} from '../../../../../core/models/user.model';
import {CacheService} from '../../../../../core/services/cache.service';
import {AuthService} from '../../../../auth/services/auth.service';
import {Date} from '../../dates/models/dates.model';
import {DatesService} from '../../dates/services/dates.service';
import {FanPlan, FanPlanInfo} from '../../settings/models/settings.model';
import {
  BulkProfileResponse,
  FanInfo,
  Note,
  Profile,
  ProfileMainInfoV2,
  ProfileOrigin,
  ProfileShort,
  ProfileTabKey
} from '../models/profile.model';
import {MoreDetails} from '../modules/profile-edit/models/profile-edit.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  myProfileShort$: Observable<ProfileShort>;
  myProfileShortUpdated$ = new BehaviorSubject<boolean | null>(null);

  profileContentTab = new BehaviorSubject<ProfileTabKey>(null);

  cachedProfilesMainInfo: {
    [key: string]: Observable<ProfileMainInfoV2>;
  } = {};

  constructor(
    private http: HttpClient,
    private dtMediaContentService: DtMediaContentService,
    private dtProfileService: DtProfilesService,
    private authService: AuthService,
    private datesService: DatesService,
    private cacheService: CacheService
  ) {}

  getMyProfileShort(): Observable<ProfileShort> {
    if (!this.myProfileShort$) {
      this.myProfileShort$ = this.http.get<ProfileShort>(`${environment.API_URL}/profiles/profile/my/short`).pipe(
        map((myProfileShort) => {
          myProfileShort.avatar = this.dtMediaContentService.getMediaByUrl(myProfileShort.avatar as string);
          return myProfileShort;
        }),
        repeat({
          delay: () =>
            this.myProfileShortUpdated$.pipe(
              filter((value) => !!value),
              tap(() => {
                this.myProfileShortUpdated$.next(false);
              })
            )
        }),
        shareReplay(1)
      );
    }
    return this.myProfileShort$;
  }

  unsetMyProfileShort(): void {
    delete this.myProfileShort$;
  }

  loadPublicProfile(profileId: string): Observable<Profile> {
    const request = this.http
      .get<Profile>(`${environment.API_URL}/profiles/profile/public/${profileId}`, {
        params: {
          ignoreErrorWarning: true,
          ignoreSessionRedirect: true
        }
      })
      .pipe(
        map((profile) => {
          profile.avatarUrls = profile.avatarUrls.map((url) => {
            return this.dtMediaContentService.getMediaByUrl(url as string);
          });
          return profile;
        })
      );
    return this.cacheService.getCacheValue(
      'ProfileService.loadPublicProfile',
      profileId,
      request.pipe(shareReplay(1)),
      5000
    );
  }

  loadProfile(profileShort: ProfileShort): Observable<Profile> {
    if (profileShort) {
      const profileId = profileShort.profileId;
      let requestPath = `profiles/profile/public/${profileId}`;
      const additionalRequests = {
        avatars: of<unknown>(null),
        moreDetails: of<unknown>(null),
        fanPlan: of<unknown>(null),
        dates: of<unknown>(null)
      };
      additionalRequests.avatars = this.getProfileAvatars(profileShort.userId);
      if (this.authService.user) {
        const isMyProfile = this.authService.user.profileId === profileId;
        requestPath = isMyProfile ? `profiles/profile/my` : `profiles/profile/user/main/${profileId}`;
        let moreDetailsRequestPath = `profiles/profile/additional-info/${profileId}`;
        moreDetailsRequestPath = isMyProfile ? `profiles/profile/my/additional-info` : moreDetailsRequestPath;
        additionalRequests.moreDetails = this.http.get<MoreDetails>(`${environment.API_URL}/${moreDetailsRequestPath}`);
        additionalRequests.fanPlan = this.getProfileFanPlan(profileId);
        additionalRequests.dates = this.datesService.getProfileDates(profileId);
      }
      const loadProfileRequest = this.http.get<Profile>(`${environment.API_URL}/${requestPath}`, {
        params: {withAvatar: true, ignoreErrorWarning: true}
      });
      return forkJoin({
        profile: loadProfileRequest,
        ...additionalRequests
      }).pipe(
        map((response) => {
          const profile = response.profile;
          const avatarPath = profile.avatarUrl ?? profile.avatarUrls ?? [];
          profile.avatarUrl = avatarPath?.map((url) => {
            return this.dtMediaContentService.getMediaByUrl(url as string);
          });
          profile.shortProfile = profileShort;
          if (!profile?.shortProfile?.avatar) {
            const [primaryAvatar] = profile.avatarUrl;
            profile.shortProfile.avatar = primaryAvatar;
          }
          if (!profile?.shortProfile?.username) {
            profile.shortProfile.username = profile?.profileSummary?.username;
          }
          if (response.avatars) {
            profile.avatarUrl = response.avatars as DtMedia[];
          }
          if (response.moreDetails) {
            profile.moreDetails = response.moreDetails as MoreDetails;
          } else {
            profile.moreDetails = profile.additionalInfo;
          }
          if (response.fanPlan) {
            profile.fanPlan = response.fanPlan as FanPlan;
            profile.sentFanRequest = (response.fanPlan as FanPlan).hasPendingFanRequest;
          }
          if (response.dates) {
            profile.dates = response.dates as Date[];
          }
          return profile;
        })
      );
    }
    return of({
      restrictions: [
        {
          type: RestrictionTypes.DELETED,
          description: 'Not found profile',
          date: new Date().toString()
        }
      ]
    } as unknown as Profile);
  }

  getProfileAvatars(userId: string): Observable<DtMedia[]> {
    return this.http
      .get<{[key: string]: string[]}>(`${environment.API_URL}/content/avatars/medias`, {
        params: {
          userIds: userId,
          type: 'all',
          size: 'original'
        }
      })
      .pipe(
        map((avatarsResponse) => {
          const [urls] = Object.values(avatarsResponse);
          return urls.map((url) => {
            return this.dtMediaContentService.getMediaByUrl(url as string);
          });
        })
      );
  }

  getProfileFanInfo(profileId: string): Observable<FanInfo> {
    return this.http.get<FanInfo>(`${environment.API_URL}/profiles/subscriptions/fan/${profileId}`);
  }

  getProfileFanPlan(profileId: string): Observable<FanPlan> {
    return this.http
      .get<FanPlanInfo>(
        `${environment.API_URL}/profiles/subscriptions/fan-plans/${this.authService.user.profileId}/${profileId}`
      )
      .pipe(
        map((fanPlanInfo) => {
          const fanPlan = fanPlanInfo.fanPlan;
          if (!fanPlanInfo.canHaveFreeTrial) {
            fanPlan.freeTrial = null;
          }
          fanPlan.hasPendingFanRequest = fanPlanInfo.hasPendingFanRequest;
          return fanPlan;
        })
      );
  }

  setProfileFollowing(ownerId: string, targetId: string): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/profile/reactions/follow`, {
      owner: ownerId,
      targetProfileId: targetId
    });
  }

  setProfileLike(targetProfileId: string): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/profile/reactions/like`, {
      targetProfileId
    });
  }

  report(
    targetId: string,
    data: {report: string; description?: string; entityId: string; contentType: string}
  ): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/profile/actions/rep-block`, {
      targetProfileId: targetId,
      data
    });
  }

  blockProfile(targetId: string): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/blocklist`, {targetId: targetId});
  }

  setFanRequest(targetUserId: string, ignoresFreeTrail: boolean): Observable<{success: boolean}> {
    return this.http.patch<{success: boolean}>(`${environment.API_URL}/profiles/subscriptions/fan-request`, {
      targetUserId,
      ignoresFreeTrail,
      makeRequestIfNoRequestSet: false
    });
  }

  searchMentionProfiles(username: string): Observable<ProfileShort[]> {
    return this.http.get<ProfileShort<string[]>[]>(`${environment.API_URL}/profiles/search/caption/${username}`).pipe(
      map((profiles) => {
        return profiles.map((profile) => {
          return this.dtProfileService.convertProfileShortAvatars(profile);
        });
      })
    );
  }

  searchShareProfiles(username: string): Observable<ProfileShort[]> {
    return this.http.get<ProfileShort<string[]>[]>(`${environment.API_URL}/profiles/search/share/${username}`).pipe(
      map((profiles) => {
        return profiles.map((profile) => {
          return this.dtProfileService.convertProfileShortAvatars(profile);
        });
      })
    );
  }

  searchProfiles(username: string): Observable<ProfileShort[]> {
    return this.http.get<ProfileShort<string[]>[]>(`${environment.API_URL}/profiles/search/general/${username}`).pipe(
      map((profiles) => {
        return profiles.map((profile) => {
          return this.dtProfileService.convertProfileShortAvatars(profile);
        });
      })
    );
  }

  searchProfile(username: string): Observable<ProfileShort> {
    let params: {profileId: string};
    if (this.authService.user) {
      params = {profileId: this.authService.user.profileId};
    }
    const request = this.http
      .get<ProfileShort>(`${environment.API_URL}/profiles/search/complex/${username}`, {
        params: {...params, ignoreErrorWarning: true}
      })
      .pipe(
        map((profile) => {
          if (profile) {
            profile.avatar = this.dtMediaContentService.getMediaByUrl(profile.avatar as string);
          }
          return profile;
        }),
        catchError((errorInfo) => {
          return of(null);
        })
      );
    return this.cacheService.getCacheValue(
      'ProfileService.searchProfile',
      username,
      request.pipe(shareReplay(1)),
      5000
    );
  }

  getProfileNotes(profileId?: string): Observable<Note[]> {
    return this.http
      .get<DtPaginationResponse<Note>[]>(`${environment.API_URL}/profiles/notes`, {
        params: {page: 1, perPage: 10, target: profileId}
      })
      .pipe(
        map(([response]) => {
          return response.data;
        })
      );
  }

  setProfileNote(target: string, text: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/notes`, {target, note: text});
  }

  updateProfileNote(noteId: string, text: string): Observable<void> {
    return this.http.put<void>(`${environment.API_URL}/profiles/notes/${noteId}`, {note: text});
  }

  deleteProfileNote(noteId: string): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/profiles/notes/${noteId}`);
  }

  getMyProfileBalance(): Observable<{
    token: {balance: number};
    spareTokens: number;
    baseCurrency: string;
    rate: number;
  }> {
    return this.http.get<{token: {balance: number}; spareTokens: number; baseCurrency: string; rate: number}>(
      `${environment.API_URL}/profiles/user/my/balance`
    );
  }

  setProfileWatch(profileId: string, profileOrigin: ProfileOrigin): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/profile/${profileId}/watch`, {...profileOrigin});
  }

  setOnlineStatus(): Observable<void> {
    return this.http.post<void>(
      `${environment.API_URL}/profiles/profile/my/online`,
      {},
      {
        params: {
          ignoreSessionRedirect: true
        }
      }
    );
  }

  getMainInfoByProfileId(profileId: string, fromCache?: boolean): Observable<ProfileMainInfoV2> {
    if (fromCache) {
      if (!this.cachedProfilesMainInfo[profileId]) {
        this.cachedProfilesMainInfo[profileId] = this.getMainInfoByProfileIdRequest(profileId).pipe(shareReplay(1));
      }
      return this.cachedProfilesMainInfo[profileId];
    }
    return this.getMainInfoByProfileIdRequest(profileId);
  }

  clearCachedProfiles(): void {
    this.cachedProfilesMainInfo = {};
  }

  private getMainInfoByProfileIdRequest(profileId: string): Observable<ProfileMainInfoV2> {
    return this.http
      .get<ProfileMainInfoV2>(`${environment.API_URL}/profiles/profile/user/main/${profileId}`, {
        params: {withAvatar: true, ignoreErrorWarning: true}
      })
      .pipe(
        map((profile) => {
          const hasSubscription = profile.targetUserHasPremium || profile.isFanToViewer;
          const invisibleAvatar = profile.visibility || profile.invisible;
          if (invisibleAvatar && !hasSubscription) {
            profile.avatarUrl = [`${environment.APP_URL}/assets/images/invisible-avatars/${invisibleAvatar}.svg`];
            profile.profileSummary = {
              ...profile.profileSummary,
              username: `Unknown ${invisibleAvatar}`
            };
            profile.isFanToViewer = false;
            profile.isOnline = false;
            profile.targetUserHasPremium = false;
            profile.profileId = profileId;
          } else {
            // treat as regular user
            profile.visibility = null;
            profile.invisible = null;
          }
          profile.avatarUrl = profile.avatarUrl.map((url) => this.dtMediaContentService.getMediaByUrl(url as string));
          // add image type for svg icons event if content service does not support it
          if (invisibleAvatar && !hasSubscription) {
            profile.avatarUrl[0].type = 'image';
          }
          return profile;
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            // create mock profile for deleted users
            const profile = {
              deleted: true,
              avatarUrl: ['assets/images/avatars/default-avatar.png'],
              profileSummary: {
                username: 'Deleted user'
              },
              profileId: profileId
            };
            return of(profile as any);
          }
          return throwError(err);
        })
      );
  }

  getBulkProfiles(profileIds: string[]): Observable<BulkProfileResponse> {
    return this.http.get<BulkProfileResponse>(`${environment.API_URL}/profiles/profile/user/bulk`, {
      params: {profileIds: [...profileIds]}
    });
  }

  getMainInfoListByProfileIds(profileIds: string[]): Observable<ProfileMainInfoV2[]> {
    const list = new Set(profileIds);

    const requests: Observable<ProfileMainInfoV2>[] = [];

    list.forEach((id) => {
      requests.push(this.getMainInfoByProfileId(id, false));
    });

    if (list.size) {
      return forkJoin(requests);
    } else {
      return of([]);
    }
  }
}
