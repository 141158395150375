import {ActionReducerMap} from '@ngrx/store';

import {profileReducer} from '../../../features/main/pages/profile/store/reducers/profile.reducers';
import {PROFILE_FEATURE_KEY} from '../../../features/main/pages/profile/store/state/profile.state';
import {AppState} from '../state/app.state';
import {USER_FEATURE_KEY} from '../state/user.state';
import {userReducers} from './user.reducers';

export const appReducers: ActionReducerMap<AppState> = {
  [USER_FEATURE_KEY]: userReducers,
  [PROFILE_FEATURE_KEY]: profileReducer
};
