import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {DtPaginationResponse} from '@ui/core/models/dt-pagination.model';
import {DtMediaContentService} from '@ui/core/services/dt-media-content.service';
import {fromUser} from 'projects/dating-app/src/app/core/store/selectors';
import {map, Observable, of, switchMap, take} from 'rxjs';

import {environment} from '../../../../../../environments/environment';
import {DateUtil} from '../../../../../core/utils/date.util';
import {Date, DatesData, DatesFilters, DatesParams} from '../models/dates.model';

@Injectable({
  providedIn: 'root'
})
export class DatesService {
  user$ = this.store.select(fromUser.selectUser);

  private datesData: DatesData;

  constructor(
    private http: HttpClient,
    private store: Store,
    private dtMediaContentService: DtMediaContentService
  ) {}

  getDatesFilters(): Observable<DatesFilters> {
    return this.http.get<DatesFilters>(`${environment.API_URL}/content/availability/filter`);
  }

  setDatesFilters(filters: DatesFilters): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/content/availability/filter`, {...filters});
  }

  getDatesData(): DatesData {
    return this.datesData;
  }

  setDatesData(datesData: DatesData): void {
    this.datesData = datesData;
  }

  getDates(params: DatesParams): Observable<DtPaginationResponse<Date>> {
    return this.http
      .get<DtPaginationResponse<Date>>(`${environment.API_URL}/content/availability/search`, {
        params: {...params}
      })
      .pipe(
        map((response) => {
          response.data = response.data.map((date) => {
            const avatars = date.profileInfo.avatars.map((avatarUrl) => {
              return this.dtMediaContentService.getMediaByUrl(avatarUrl);
            });
            const [primaryAvatar] = avatars;
            date.profile = {
              avatar: primaryAvatar,
              avatars: avatars,
              userId: date.owner.userId,
              profileId: date.owner._id,
              profileType: date.owner.type,
              username: date.owner.person1.username,
              hasPremiumSubscription: date.profileInfo.hasSystemSub,
              isFan: date.profileInfo.isFan,
              isOnline: date.profileInfo.onlineStatus,
              isVerified: date.profileInfo.isVerified
            };
            return date;
          });
          return response;
        }),
        switchMap((response) => {
          return this.setDatesInfo(response.data).pipe(
            map((dates) => {
              response.data = dates;
              return response;
            })
          );
        })
      );
  }

  getMyDates(): Observable<Date[]> {
    return this.http.get<{data: Date[]}>(`${environment.API_URL}/content/availability/my`).pipe(
      switchMap((response) => {
        return this.setDatesInfo(response.data);
      })
    );
  }

  getProfileDates(profileId: string): Observable<Date[]> {
    return this.http.get<{data: Date[]}>(`${environment.API_URL}/content/availability/${profileId}`).pipe(
      switchMap((response) => {
        const dates = response.data.sort((a, b) => {
          return a.startAt > b.startAt ? 1 : -1;
        });
        return this.setDatesInfo(dates);
      })
    );
  }

  private setDatesInfo(dates: Date[]): Observable<Date[]> {
    return of(dates).pipe(
      map((dates) => {
        return dates.map((date) => {
          this.setDateBasicInfo(date);
          this.setDateTimezoneInfo(date);
          return date;
        });
      }),
      switchMap((dates) => {
        return this.user$.pipe(
          take(1),
          map((user) => {
            return dates.map((date) => {
              if (date.budget !== 'other') {
                // const currency = user.balance.baseCurrency as Currency;
                // date.budgetEqual = `≈ up to ${user.balance.rate * Number(date.budget)} ${currency.symbol}`;
                switch (date.budget) {
                  case '250':
                    date.budgetEqual = 'Gold';
                    break;
                  case '500':
                    date.budgetEqual = 'Platinum';
                    break;
                  case '1000':
                    date.budgetEqual = 'Diamond';
                    break;
                }
              } else {
                date.budgetEqual = 'Other';
              }
              return date;
            });
          })
        );
      })
    );
  }

  private setDateBasicInfo(date: Date): void {
    const dateDuration = DateUtil.hoursBetweenDates(new Date(date.startAt), new Date(date.expiredAt));
    date.isOneDay = dateDuration < 24;
    date.type = date.isOnline ? 'Online date' : 'Offline date';
    date.tag = date?.tag?.replace('_', ' ')?.toLowerCase();
  }

  private setDateTimezoneInfo(date: Date): void {
    const reverseOffset = +date.timezone * -1;
    const reverseLocalOffset = new Date().getTimezoneOffset() * 60 * -1;
    const fullReverseOffset = reverseOffset + reverseLocalOffset;
    date.isLocationTimezone = true;
    date.startAtLocation = DateUtil.getTimezoneUTC(new Date(date.startAt), fullReverseOffset).replace('Z', '');
    date.expiredAtLocation = DateUtil.getTimezoneUTC(new Date(date.expiredAt), fullReverseOffset).replace('Z', '');
    date.isDifferentTimezone = reverseLocalOffset !== +date.timezone;
  }

  createDate(date: Date): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/content/availability/my`, {...date});
  }

  deleteDate(dateId: string): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/content/availability/my/${dateId}`);
  }
}
