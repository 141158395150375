import {Injectable} from '@angular/core';
import {DtLocation} from '@ui/modules/components/dt-location-select/models/dt-location-select.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DtLocationSelectService {
  predefinedLocationsChanges = new BehaviorSubject<DtLocation[]>([]);

  get predefinedLocations(): DtLocation[] {
    return this.predefinedLocationsChanges.value;
  }

  constructor() {}

  setPredefinedLocations(locations: DtLocation[]): void {
    this.predefinedLocationsChanges.next(locations);
  }
}
