import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DtButtonModule} from '@ui/modules/components/dt-button/dt-button.module';
import {DtDialogModule} from '@ui/modules/components/dt-dialogs/dt-dialog.module';
import {DtFieldsModule} from '@ui/modules/components/dt-fields/dt-fields.module';
import {DtMapDialogComponent} from '@ui/modules/components/dt-location-select/components/dt-map-dialog/dt-map-dialog.component';
import {DtLocationSelectComponent} from '@ui/modules/components/dt-location-select/dt-location-select.component';
import {DtTranslateModule} from '@ui/modules/pipes/dt-translate/dt-translate.module';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';

@NgModule({
  declarations: [DtLocationSelectComponent, DtMapDialogComponent],
  exports: [DtLocationSelectComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzDividerModule,
    NzIconModule,
    DtDialogModule,
    DtButtonModule,
    DtFieldsModule,
    DtTranslateModule
  ]
})
export class DtLocationSelectModule {}
