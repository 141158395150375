import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DtTranslateModule} from '@ui/modules/pipes/dt-translate/dt-translate.module';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {FlushDatepickerRangeFieldComponent} from './flush-datepicker-range-field.component';

@NgModule({
  declarations: [FlushDatepickerRangeFieldComponent],
  exports: [FlushDatepickerRangeFieldComponent],
  imports: [CommonModule, NzDatePickerModule, FormsModule, NzIconModule, DtTranslateModule]
})
export class FlushDatepickerRangeFieldModule {}
