import {NgModule} from '@angular/core';
import {DtButtonModule} from '@ui/modules/components/dt-button/dt-button.module';
import {DtDialogModule} from '@ui/modules/components/dt-dialogs/dt-dialog.module';
import {DtMediaContentModule} from '@ui/modules/components/dt-media-content/dt-media-content.module';
import {DtTranslateModule} from '@ui/modules/pipes/dt-translate/dt-translate.module';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {FlushCheckboxFieldModule} from '../../../fields/flush-checkbox-field/flush-checkbox-field.module';
import {SectionDescriptionModule} from '../../../section-description/section-description.module';
import {ConfirmDialogComponent} from './confirm-dialog.component';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [
    DtDialogModule,
    DtTranslateModule,
    NzIconModule,
    DtMediaContentModule,
    SectionDescriptionModule,
    FlushCheckboxFieldModule,
    DtButtonModule
  ],
  exports: [ConfirmDialogComponent]
})
export class ConfirmDialogModule {}
