import {ModuleWithProviders, NgModule} from '@angular/core';
import {
  DEFAULT_LANGUAGE,
  FakeMissingTranslationHandler,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateFakeCompiler,
  TranslateModule as ngxTranslateModule,
  TranslateModuleConfig,
  TranslateParser,
  TranslateService as ngxTranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND,
  USE_STORE
} from '@ngx-translate/core';

import {DtTranslatePipe} from './dt-translate.pipe';
import {DtTranslateService} from './services/dt-translate.service';

@NgModule({
  declarations: [DtTranslatePipe],
  exports: [DtTranslatePipe]
})
export class DtTranslateModule extends ngxTranslateModule {
  static forRoot(config: TranslateModuleConfig = {}): ModuleWithProviders<DtTranslateModule> {
    return {
      ngModule: DtTranslateModule,
      providers: [
        config.loader,
        config.parser || {
          provide: TranslateParser,
          useClass: TranslateDefaultParser
        },
        config.missingTranslationHandler || {
          provide: MissingTranslationHandler,
          useClass: FakeMissingTranslationHandler
        },
        config.compiler || {
          provide: TranslateCompiler,
          useClass: TranslateFakeCompiler
        },
        {provide: USE_STORE, useValue: config.isolate},
        {provide: USE_DEFAULT_LANG, useValue: config.useDefaultLang},
        {provide: USE_EXTEND, useValue: config.extend},
        {provide: DEFAULT_LANGUAGE, useValue: config.defaultLanguage},
        TranslateStore,
        DtTranslateService,
        DtTranslatePipe,
        ngxTranslateService
      ]
    };
  }
}
