import {isPlatformServer} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Params} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DtUtmService {
  private utmKeys = [
    {key: 'utmSource', queryKey: 'utm_source'},
    {key: 'sessionId', queryKey: 'Sessionid'},
    {key: 'visitId', queryKey: 'VISIT_ID'},
    {key: 'refId', queryKey: 'ref_id'},
    {key: 'referralId', queryKey: 'referralId'}
  ];

  private platformId = Inject(PLATFORM_ID);

  constructor() {}

  getUtmList(useQueryKeys?: boolean): {[key: string]: string} {
    const list: {[key: string]: string} = {};
    this.utmKeys.forEach(({key, queryKey}) => {
      const value = this.getUtm(key);
      if (value) {
        list[useQueryKeys ? queryKey : key] = value;
      }
    });
    return list;
  }

  setUtmList(queryParams: Params): void {
    this.utmKeys.forEach(({key, queryKey}) => {
      if (queryKey in queryParams) {
        this.setUtm(key, queryParams[queryKey]);
      }
    });
  }

  clearUtmList(): void {
    this.utmKeys.forEach(({key}) => {
      this.clearUtm(key);
    });
  }

  getUtm(key: string): string {
    if (!isPlatformServer(this.platformId)) {
      return localStorage.getItem(key);
    }
    return null;
  }

  setUtm(key: string, value: string): void {
    if (key === 'sessionId') {
      this.clearUtm('visitId');
    }
    if (key === 'visitId') {
      this.clearUtm('sessionId');
    }
    if (key === 'refId') {
      this.clearUtm('referralId');
    }
    if (key === 'referralId') {
      this.clearUtm('refId');
    }
    localStorage.setItem(key, value);
  }

  clearUtm(key: string): void {
    localStorage.removeItem(key);
  }
}
