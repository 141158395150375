import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DtButtonModule} from '@ui/modules/components/dt-button/dt-button.module';
import {DtTranslateModule} from '@ui/modules/pipes/dt-translate/dt-translate.module';
import {NzIconModule} from 'ng-zorro-antd/icon';

import {LegacyIconModule} from '../legacy-icon/legacy-icon.module';
import {SectionDescriptionModule} from '../section-description/section-description.module';
import {EmptyContentComponent} from './empty-content.component';

@NgModule({
  declarations: [EmptyContentComponent],
  imports: [CommonModule, LegacyIconModule, DtButtonModule, NzIconModule, DtTranslateModule, SectionDescriptionModule],
  exports: [EmptyContentComponent]
})
export class EmptyContentModule {}
