// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  HUBSPOT_API_URL: 'https://xxuqktyz.hunsy.dev/',
  CDN_PATH: 'https://hunsy.dev/landing',
  COOKIE_SCRIPT_ID: 'c43d89ec8afdcafd63afc43393144918',
  BLOG_COLLECTION_ID: '69465103825',
  BLOG_PORTAL_ID: '26766126',
  BLOG_CONTACT_US_FORM_ID: 'f0e941cb-102c-4015-a8fe-2e5bbb62311d',
  HUBSPOT_WAITLIST_FORM_ID: '5748349e-b101-4049-9409-13d976267387',
  USER_PILOT_KEY: 'NX-38778449'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
