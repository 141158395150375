import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {DtTranslateModule} from '@ui/modules/pipes/dt-translate/dt-translate.module';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzMentionModule} from 'ng-zorro-antd/mention';

import {FlushTextareaFieldComponent} from './flush-textarea-field.component';

@NgModule({
  declarations: [FlushTextareaFieldComponent],
  exports: [FlushTextareaFieldComponent],
  imports: [CommonModule, DtTranslateModule, ReactiveFormsModule, NzInputModule, NzMentionModule]
})
export class FlushTextareaFieldModule {}
