import {HttpClient, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DtPaginationResponse} from '@ui/core/models/dt-pagination.model';
import {DtProfileShort} from '@ui/core/models/dt-profile.model';
import {DtProfilesService} from '@ui/core/services/dt-profiles.service';
import {
  BehaviorSubject,
  filter,
  forkJoin,
  fromEvent,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import {io, Socket} from 'socket.io-client';
import {v4 as uuid} from 'uuid';

import {environment} from '../../../../../../environments/environment';
import {NotificationsService} from '../../../../../core/services/notifications.service';
import {AuthService} from '../../../../auth/services/auth.service';
import {
  ChatEvents,
  Conversation,
  ConversationPermissions,
  ConversationResponse,
  ConversationSearchProfile,
  ConversationsResponse,
  ConversationsSearchResponse,
  Message,
  MessageReceiveEventData,
  MessagesResponse,
  UnreadMessagesInfo
} from '../models/chat.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private socket: Socket;
  private socketStatus$ = new BehaviorSubject(null);

  unreadMessagesInfo: UnreadMessagesInfo = {total: 0, channels: {}};
  unreadMessagesAmount: string = '';

  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private http: HttpClient,
    private dtProfilesService: DtProfilesService,
    private authService: AuthService,
    protected notificationService: NotificationsService
  ) {}

  init(): void {
    this.initConnection();
    this.handleMessageReceiving();
    this.getUnreadMessagesAmount().subscribe();
  }

  destroy(): void {
    this.socket?.offAny();
    this.socket?.disconnect();
    this.socketStatus$.next(false);
    this.destroy$.next();
  }

  private initConnection(): void {
    this.getConnectionToken().subscribe(({token}) => {
      this.socket = io(environment.CHAT_WS_URL, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        query: {
          token,
          profileId: this.authService.user.profileId
        }
      });
      this.handleConnection();
    });
  }

  private getConnectionToken(): Observable<{token: string}> {
    return this.http.get<{token: string}>(`${environment.API_URL}/chat/access-token`);
  }

  private handleConnection(): void {
    this.socket.on('connect', () => {
      if (!this.socketStatus$.value) {
        this.joinRoom();
        this.socketStatus$.next(true);
      } else {
        this.socket.offAny();
        this.socket.disconnect();
        this.socketStatus$.next(false);
        this.initConnection();
      }
    });
  }

  private joinRoom(): void {
    this.socket.emit('room', `chat-${this.authService.user.profileId}`);
  }

  onSocketMessage<T>(messageName: string): Observable<T> {
    return this.socketStatus$.pipe(
      filter((value) => !!value),
      switchMap(() => {
        return fromEvent(this.socket, messageName);
      })
    );
  }

  onAnySocketMessage<T>(): Observable<T> {
    return this.socketStatus$.pipe(
      filter((value) => !!value),
      switchMap(
        () =>
          new Observable<T>((observer) => {
            const eventHandler = (eventName: string, ...args: T[]) => {
              observer.next(...args);
            };
            this.socket.onAny(eventHandler);
            return () => {
              this.socket.offAny(eventHandler);
            };
          })
      )
    );
  }

  sendSocketMessage<T>(messageName: string, data: T): void {
    this.socket.emit(messageName, data);
  }

  private handleMessageReceiving(): void {
    this.onSocketMessage<MessageReceiveEventData>(ChatEvents.MESSAGE_RECEIVED)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        this.getUnreadMessagesAmount().subscribe();
        const isChatPage = this.router.url.includes('/chat');
        if (!event.reciverMuted && !isChatPage) {
          this.notificationService.createNotification(`New message`);
        }
      });
  }

  getUnreadMessagesAmount(): Observable<UnreadMessagesInfo> {
    return this.http.get<UnreadMessagesInfo>(`${environment.API_URL}/chat/unread-amount`).pipe(
      tap((info) => {
        this.unreadMessagesInfo = info;
        if (info.total) {
          this.unreadMessagesAmount = info.total > 99 ? '99+' : String(info.total);
        } else {
          this.unreadMessagesAmount = null;
        }
      })
    );
  }

  getConversationWithProfile(profileId: string): Observable<{channelId: string}> {
    return this.http.get<{channelId: string}>(`${environment.API_URL}/chat/conversations/${profileId}`);
  }

  getConversationPermissions(profileId: string): Observable<ConversationPermissions> {
    return this.http.get<ConversationPermissions>(`${environment.API_URL}/chat/conversation-permitted/${profileId}`);
  }

  setConversationRestrictions(conversation: Conversation): void {
    if (conversation.permissions) {
      const profileType = this.authService.user.profileType;
      const userIsInitiator = conversation.permissions.initiatorProfileType === profileType;
      const permissionsInfo = conversation.permissions;
      const permissions = userIsInitiator ? permissionsInfo.initiator : permissionsInfo.recipient;
      const {write, view} = permissions;
      conversation.restrictedToWrite = !write;
      conversation.restrictedToView = !view;
    }
  }

  openConversationPage(receiverProfileShort: DtProfileShort, state: {[key: string]: string} = {}): void {
    this.getConversationWithProfile(receiverProfileShort.profileId).subscribe(({channelId}) => {
      if (channelId) {
        this.router
          .navigate([`/chat/${channelId}`], {
            state: {
              ...state
            }
          })
          .then();
      } else {
        const newId = uuid();
        this.router
          .navigate([`/chat/${newId}`], {
            state: {
              receiver: receiverProfileShort,
              isNewConversation: true,
              ...state
            }
          })
          .then();
      }
    });
  }

  getMyConversations(page: number, perPage: number, filter: string): Observable<DtPaginationResponse<Conversation>> {
    return this.http
      .get<ConversationsResponse>(`${environment.API_URL}/chat/conversations/my`, {
        params: {page, perPage, filter}
      })
      .pipe(
        map((response) => {
          response.data = response.coversations.map((conversation) => {
            conversation.receiverId = conversation.unionChannelId.replace(conversation.profileId, '');
            this.setConversationRestrictions(conversation);
            return conversation;
          });
          return response;
        }),
        this.dtProfilesService.setProfilesByIds<ConversationsResponse, Conversation>(
          (response) => response.data,
          (conversation) => conversation.receiverId,
          'receiver',
          true
        )
      );
  }

  searchMyConversations(search: string): Observable<ConversationsSearchResponse> {
    return this.http
      .get<ConversationsSearchResponse>(`${environment.API_URL}/chat/conversations/my/search`, {
        params: {page: 1, perPage: 50, search}
      })
      .pipe(
        this.dtProfilesService.setProfilesByIds<ConversationsSearchResponse, ConversationSearchProfile>(
          (response) => response.profiles,
          (searchProfile) => searchProfile.senderProfileId,
          'profile',
          true
        ),
        map((response) => {
          response.conversations = response.messages.map((message) => {
            return {latestMessage: message};
          });
          return response;
        }),
        this.dtProfilesService.setProfilesByIds<ConversationsSearchResponse, Partial<Conversation>>(
          (response) => response.conversations,
          (searchProfile) => {
            const myId = this.authService.user.profileId;
            if (myId === searchProfile.latestMessage.sender_profile_id) {
              return searchProfile.latestMessage.receiver_profile_id;
            } else {
              return searchProfile.latestMessage.sender_profile_id;
            }
          },
          'receiver',
          true
        )
      );
  }

  getMyConversation(conversationId: string): Observable<Conversation> {
    return this.http
      .get<ConversationResponse>(`${environment.API_URL}/chat/conversations/my/${conversationId}`, {
        params: {
          ignoreErrorWarning: true,
          ignoreSessionRedirect: true
        }
      })
      .pipe(
        map((response) => {
          const conversation = response.conversation;
          conversation.permissions = response.permissions;
          conversation.receiverId = conversation.unionChannelId.replace(conversation.profileId, '');
          this.setConversationRestrictions(conversation);
          return [conversation];
        }),
        this.dtProfilesService.setProfilesByIds<Conversation[], Conversation>(
          (response) => response,
          (conversation) => conversation.receiverId,
          'receiver',
          true
        ),
        map(([response]: Conversation[]) => response)
      );
  }

  setTranslationMyConversation(conversationId: string, enable: boolean): Observable<void> {
    return this.http.patch<void>(`${environment.API_URL}/chat/conversations/my/${conversationId}/translation`, {
      enable
    });
  }

  pinMyConversation(conversationId: string, isPin: boolean): Observable<void> {
    return this.http.patch<void>(`${environment.API_URL}/chat/conversations/my/${conversationId}/pin`, {isPin});
  }

  muteMyConversation(conversationId: string, isMuted: boolean): Observable<void> {
    return this.http.patch<void>(`${environment.API_URL}/chat/conversations/my/${conversationId}/mute`, {isMuted});
  }

  deleteMyConversation(conversationId: string): Observable<void> {
    return this.http.delete<void>(`${environment.API_URL}/chat/conversations/my/${conversationId}`);
  }

  getConversationMessages(
    conversationId: string,
    page: number,
    perPage: number,
    search: string
  ): Observable<MessagesResponse> {
    return this.http
      .get<MessagesResponse>(`${environment.API_URL}/chat/conversations/my/${conversationId}/messages`, {
        params: {page, perPage, search}
      })
      .pipe(
        map((response) => {
          response.data = response.messages;
          return response;
        }),
        switchMap((response) => {
          const repliedMessagesRequests = response.data
            .filter((message) => !!message.parent_id)
            .map((item) => {
              return this.getConversationMessage(conversationId, item.parent_id);
            });
          if (repliedMessagesRequests.length) {
            return forkJoin(repliedMessagesRequests).pipe(
              map((repliedMessages) => {
                response.data = response.data.map((message) => {
                  if (message.parent_id) {
                    message.parentMessage = repliedMessages.find((item) => item.message_id === message.parent_id);
                  }
                  return message;
                });
                return response;
              })
            );
          } else {
            return of(response);
          }
        })
      );
  }

  getConversationMessage(conversationId: string, messageId: string): Observable<Message> {
    return this.http.get<Message>(
      `${environment.API_URL}/chat/conversations/my/${conversationId}/messages/${messageId}`
    );
  }

  getMessageContent(conversationId: string, receiverId: string, fileKey: string): Observable<{url: string}> {
    return this.http.get<{url: string}>(`${environment.API_URL}/content/chats/${conversationId}/files`, {
      params: {
        receiverProfileId: receiverId,
        fileKey,
        ignoreErrorWarning: true,
        ignoreSessionRedirect: true
      }
    });
  }

  readMessage(conversationId: string, messageId: string): Observable<void> {
    return this.http.patch<void>(
      `${environment.API_URL}/chat/conversations/my/${conversationId}/messages/${messageId}/read`,
      {
        read_at: new Date().toISOString()
      }
    );
  }

  likeMessage(conversationId: string, messageId: string): Observable<void> {
    return this.http.patch<void>(
      `${environment.API_URL}/chat/conversations/my/${conversationId}/messages/${messageId}/like`,
      {}
    );
  }

  deleteMessage(conversationId: string, messageId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.API_URL}/chat/conversations/my/${conversationId}/messages/${messageId}`,
      {}
    );
  }

  sendMessage(
    conversationId: string,
    receiverProfileId: string,
    message: string,
    parentId?: string,
    actualSentAt = new Date().toISOString()
  ): Observable<Message> {
    return this.http.post<Message>(`${environment.API_URL}/chat/conversations/my/${conversationId}/messages`, {
      receiverProfileId,
      message,
      type: 'text',
      parentId,
      actualSentAt
    });
  }

  sendFile(
    conversationId: string,
    receiverProfileId: string,
    file: File,
    dontShowPolicy: boolean
  ): Observable<HttpEvent<{message: Message}>> {
    const formData = new FormData();
    formData.set('receiverProfileId', receiverProfileId);
    formData.set('files', file, file.name.toLowerCase());
    formData.append('consentCheckBox', 'true');
    formData.append('identityCheckBox', 'true');
    formData.append('TOSAgreementCheckBox', 'true');
    formData.append('doNotShowAgainCheckBox', String(dontShowPolicy));
    return this.http.post<{message: Message}>(
      `${environment.API_URL}/content/chats/${conversationId}/files`,
      formData,
      {reportProgress: true, observe: 'events'}
    );
  }

  transferTokens(receiverProfileId: string, channelId: string, amount: number): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/chat/transfer-tokens`, {
      recipientProfileId: receiverProfileId,
      channelId,
      amount
    });
  }

  givePersonalAccess(receiverProfileId: string): Observable<void> {
    return this.http.post<void>(`${environment.API_URL}/profiles/subscriptions/fan-personal`, {
      subscriberProfileId: receiverProfileId
    });
  }
}
