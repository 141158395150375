import {DtProfileType} from '@ui/core/models/dt-profile.model';

import {Currency} from '../../features/main/pages/settings/models/settings.model';

export interface UserAvailability {
  exists: boolean;
  moderation: any;
  suggestion: string[];
}

export interface User {
  userId: string;
  profileId: string;
  profileType?: DtProfileType;
  invisible: string;
  gender: string;
  person1?: {
    username: string;
    birthDate: string;
  };
  restriction: UserRestriction[];
  balance: UserBalance;
  agreement: UserAgreement;
  paymentsBlocks: UserPaymentsBlocks;
}

export interface UserRestriction {
  type: RestrictionTypes;
  description: string;
  date: string;
}

export enum RestrictionTypes {
  BANNED = 'ban',
  HIDDEN = 'hidden',
  DELETED = 'deleted'
}

export interface UserBalance {
  baseCurrency: Currency | string;
  token: {
    balance: number;
    bufferBalance: number;
  };
  fiat: {
    balance: number;
  };
  rate: number;
  tokenPriceFiat: number;
}

export interface UserAgreement {
  mediaAgreements?: {
    activatedAt: string;
  };
  chatMediaAgreements?: {
    activatedAt: string;
  };
}

export interface UserPaymentsBlocks {
  request_lock_ps_purchase_with_tokens: number;
  request_lock_ps_purchase_with_card: number;
  request_lock_token_purchase: number;
  request_lock_token_withdraw: number;
  request_lock_attach_card: number;
}
